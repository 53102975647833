<div class="card">
    <div class="card-body">
        <div class="table-responsive">
            <table class="table table-sm">
                <thead>
                    <tr class="table-row table-row-head">
                        <th *ngFor="let col of report.colsMeta" (click)="sortTable(col.columnLabel)" 
                            class="cursor-pointer"> 
                            {{getColLabelPrettified(col)}} 
                            <span *ngIf="sortColumn === getColLabel(col)" class="text-muted xsmall"> {{ sortDirection === 'asc' ? '▲' : '▼' }}</span>
                        </th>
                    </tr>
                </thead>
                <tbody class="table-body">
                    <tr *ngFor="let row of report.data" class="table-row" >
                        <td *ngFor="let value of row; index as i" >
                            {{getValue(value, i)}}
                        </td>
                    </tr>
                </tbody>
            </table>

        </div>
    </div>
</div>
<div class="d-flex flex-column h-100">
  <div class="d-flex justify-content-between mb-5">
    <a class="navbar-brand mx-3" routerLink="/admin">
      <app-icon name="logo" class="icon-black"></app-icon>
    </a>
    <button
      type="button"
      class="btn btn-primary btn-nav rounded-circle d-md-none"
      [attr.data-bs-dismiss]="getDismiss()"
      aria-label="Close"
    >
      <app-icon name="close" class="icon-white icon-md"></app-icon>
    </button>
  </div>
  <ul class="navbar-nav">
    <li
      class="nav-item py-1 my-0 my-md-1 py-md-2"
      [attr.data-bs-dismiss]="getDismiss()"
      routerLink="/admin/dashboard"
      [ngClass]="{ active: isActive('dashboard') }"
    >
      <app-icon name="person" class="icon-secondary"></app-icon>
      <a class="nav-link">Dashboard</a>
    </li>
    <li
      class="nav-item py-1 my-0 my-md-1 py-md-2"
      [attr.data-bs-dismiss]="getDismiss()"
      routerLink="/admin/school"
      [ngClass]="{ active: isActive('school') }"
    >
      <app-icon name="person" class="icon-secondary"></app-icon>
      <a class="nav-link">Accredited customers</a>
    </li>
    <a
      class="nav-item py-1 my-0 my-md-1 py-md-2"
      [attr.data-bs-dismiss]="getDismiss()"
      href="https://casa.callanonline.com"
    >
      <app-icon name="person" class="icon-secondary"></app-icon>
      <a class="nav-link">Accreditation process</a>
    </a>
    <li
      class="nav-item py-1 my-0 my-md-1 py-md-2"
      [attr.data-bs-dismiss]="getDismiss()"
      routerLink="/admin/reports"
    >
      <app-icon name="report" class="icon-secondary"></app-icon>
      <a class="nav-link">Reports</a>
    </li>
  </ul>
  <ul class="navbar-nav mt-auto mb-3">
    <li
      class="nav-item py-1 my-0 my-md-1 py-md-2"
      [attr.data-bs-dismiss]="getDismiss()"
      (click)="themeService.onThemeChange(theme)"
    >
      <app-icon
        [name]="
          (themeService.getTheme() | async) !== 'dark'
            ? 'dark-mode'
            : 'light-mode'
        "
        class="icon-secondary"
      ></app-icon>
      <span class="nav-link">{{ themeService.getThemeName(theme) }}</span>
    </li>
  </ul>
</div>

import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import {ToastNotification, ToastNotificationsService} from "./toast-notifications.service";
import {ErrorBase} from "../model/server";
@Injectable({
  providedIn: 'root'
})
/*
  Interceptor for http request responsible to prepare common error structure
*/
export class HttpErrorsInterceptorService implements HttpInterceptor {

  constructor(
    private toasts: ToastNotificationsService) { }
  intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {

      return next.handle(req).pipe(catchError(err => {
        let error = err.error as ErrorBase;
        if (err.status === 0 ) {
          error = new ErrorBase('Lost connection. Check your Internet connection or try again later.');
          error.errorCode = 'CONNECTION_ERROR';
          error.interactive = false;
          error.developerMessage = 'Request failed. Check the connection or server status.';
        } else if (!error) {
          error = new ErrorBase('Server Error');
          error.developerMessage = err.error;
          error.errorCode = 'HTTP:${err.status}';
        } else if (error && error.interactive) {
          // skip regular error processing
          return throwError(error);
        } else if (error.userMessage === "timeout exception - please narrow your date range"){
          return throwError(error)
        }
        let message = error.userMessage;
        if (message === "Internal server error.") message = error.developerMessage;
        this.toasts.display(new ToastNotification("Communication Error",`${message}`))
        return throwError(error);
      }));
    }
}

<div class="d-flex flex-column h-100">
  <div class="d-flex justify-content-between mb-5">
      <a class="navbar-brand mx-3" routerLink="/school/{{schoolId}}/dashboard">
          <app-icon name="logo" class="icon-black"></app-icon>
      </a>
      <button type="button" class="btn btn-primary btn-nav rounded-circle d-md-none" [attr.data-bs-dismiss]="getDismiss()" aria-label="Close">
        <app-icon name="close" class="icon-white icon-md"></app-icon>
      </button>
  </div>
  <div class="d-flex flex-column h-100 overflow-scroll">
    <ul class="navbar-nav">
      <li class="nav-item py-1 my-0 py-md-2" [attr.data-bs-dismiss]="getDismiss()" routerLink="/school/{{schoolId}}/students" [ngClass]="{'active': isActive('student')}">
        <app-icon name="person" class="icon-secondary"></app-icon>
        <a class="nav-link">Students</a>
      </li>
      <li class="nav-item py-1 my-0 py-md-2" [attr.data-bs-dismiss]="getDismiss()" routerLink="/school/{{schoolId}}/teachers" [ngClass]="{'active': isActive('teacher')}">
        <app-icon name="briefcase" class="icon-secondary"></app-icon>
        <a class="nav-link">Teachers</a>
      </li>
      <li class="nav-item py-1 my-0 py-md-2" *ngIf="!disableVideo" [attr.data-bs-dismiss]="getDismiss()" routerLink="/school/{{schoolId}}/groups" [ngClass]="{'active': isActive('group;room')}">
        <app-icon name="dashboard" class="icon-secondary"></app-icon>
        <a class="nav-link">Groups</a>
      </li>
      <li class="nav-item py-1 my-0 py-md-2" *ngIf="!disableVideo && isGroupVisible" [attr.data-bs-dismiss]="getDismiss()" routerLink="/school/{{schoolId}}/v2_groups" [ngClass]="{'active': isActive('v2_groups')}">
        <app-icon name="dashboard" class="icon-secondary"></app-icon>
        <a class="nav-link">Groups (new)</a>
      </li>
      <li class="nav-item py-1 my-0 py-md-2" *ngIf="!disableVideo" [attr.data-bs-dismiss]="getDismiss()" routerLink="/school/{{schoolId}}/video" [ngClass]="{'active': isActive('video;schedule')}">
        <app-icon name="schedule" class="icon-secondary"></app-icon>
        <a class="nav-link">Schedule</a>
      </li>
      <li class="nav-item py-1 my-0 py-md-2 flex-column" [ngClass]="{'active': isActive('report')}">
        <div class="d-flex me-auto">
          <app-icon name="report" class="icon-secondary"></app-icon>
          <a class="nav-link" href="#" id="navbarDropdown" role="button" data-bs-toggle="collapse" data-bs-target="#collapseReport" aria-haspopup="true" aria-expanded="false">
            Reports
          </a>
        </div>
        <div class="collapse nav-item my-0 flex-column ms-3 me-auto p-0" id="collapseReport" aria-labelledby="navbarDropdown">
          <a class="nav-link w-100 xsmall text-muted" [attr.data-bs-dismiss]="getDismiss()" routerLink="/school/{{schoolId}}/reports/teacher-lesson-types"
             *ngIf="isCallanOnline()"
          >Teacher Lesson Types</a>
          <a class="nav-link w-100 xsmall text-muted" [attr.data-bs-dismiss]="getDismiss()" routerLink="/school/{{schoolId}}/reports/lessons"
             *ngIf="isCallanOnline()"
          >Lessons detailed</a>
          <a class="nav-link w-100 xsmall text-muted" [attr.data-bs-dismiss]="getDismiss()" routerLink="/school/{{schoolId}}/reports/video/activities"
          >OCC group usage</a>
          <a class="nav-link w-100 xsmall text-muted" [attr.data-bs-dismiss]="getDismiss()" routerLink="/school/{{schoolId}}/reports/video/teacher-attendance"
          >OCC teacher usage</a>
          <a class="nav-link w-100 xsmall text-muted" [attr.data-bs-dismiss]="getDismiss()" routerLink="/school/{{schoolId}}/reports/new-reports"
          >New reports</a>
        </div>
      </li>
      <li class="nav-item py-1 my-0 py-md-2" [attr.data-bs-dismiss]="getDismiss()" routerLink="/school/{{schoolId}}/documents" [ngClass]="{'active': isActive('documents')}">
        <app-icon name="file" class="icon-secondary"></app-icon>
        <a class="nav-link">Documents</a>
      </li>
      <li class="nav-item py-1 my-0 py-md-2" [attr.data-bs-dismiss]="getDismiss()" routerLink="/school/{{schoolId}}/news" [ngClass]="{'active': isActive('news')}">
        <app-icon name="newspaper" class="icon-secondary"></app-icon>
        <a class="nav-link">News</a>
      </li>
      <li class="nav-item py-1 my-0 py-md-2" *ngIf="hasManyManagedSchools() && !(isAdminLoggedInAsManager() | async)" [ngClass]="{'active': isActive('school')}">
        <app-icon name="school" class="icon-secondary"></app-icon>
        <a class="nav-link cursor-pointer" [attr.data-bs-dismiss]="getDismiss()" routerLink="/school/{{schoolId}}/schools">Schools</a>
      </li>
      <li class="nav-item py-1 my-0 py-md-2 flex-column">
        <div class="d-flex me-auto">
          <app-icon name="shop" class="icon-secondary icon-secondary-stroke"></app-icon>
          <a class="nav-link" href="#" id="shopDropdown" role="button" data-bs-toggle="collapse" data-bs-target="#collapseShop" aria-haspopup="true" aria-expanded="false">
            Shop
          </a>
        </div>
        <div class="collapse nav-item my-0 flex-column ms-3 me-auto p-0" id="collapseShop" aria-labelledby="shopDropdown">
          <a class="nav-link w-100 xsmall text-muted" [attr.data-bs-dismiss]="getDismiss()" href="https://shop.callan.co.uk/" target="_blank"
          >Buy books</a>
          <a class="nav-link w-100 xsmall text-muted text-no-capitalize" [attr.data-bs-dismiss]="getDismiss()" data-bs-toggle="modal" data-bs-target="#creditsCart"
          >Buy eBooks</a>
        </div>
      </li>
      <li class="nav-item py-1 my-0 py-md-2 d-md-none text-nowrap">
        <app-icon name="log-out" class="icon-secondary"></app-icon>
        <a class="nav-link cursor-pointer" [attr.data-bs-dismiss]="getDismiss()" (click)="logout()">Log out</a>
      </li>
    </ul>
    <ul class="navbar-nav mt-auto mb-3">
      <li *ngIf="isAdminLoggedInAsManager() | async" class="nav-item py-1 my-0 py-md-2" [attr.data-bs-dismiss]="getDismiss()" (click)="logOutFromManager()">
        <app-icon name="log-out" class="icon-secondary"></app-icon>
        <a class="nav-link">Back to Admin</a>
      </li>
      <li class="nav-item py-1 my-0 py-md-2" [attr.data-bs-dismiss]="getDismiss()" (click)="themeService.onThemeChange(theme)">
        <app-icon [name]="(themeService.getTheme() | async) !== 'dark' ? 'dark-mode' : 'light-mode'" class="icon-secondary"></app-icon>
        <span class="nav-link">{{ themeService.getThemeName(theme) }}</span>
      </li>
    </ul>
  </div>
</div>

<div class="bg-workspace min-vh-100">
    <top-bar [title]="'Reports'"></top-bar>
    <div class="container py-4 px-md-5" >
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb" >
          <li class="breadcrumb-item" aria-current="page"><a href="/" routerLink="/school/{{schoolId}}/dashboard">Home</a></li>
          <li class="breadcrumb-item active" aria-current="page">Reports</li>
        </ol>
      </nav>
      <div class="card mb-4">
        <div class="card-body">
          <div class="form-floating mb-4">
            <select class="form-select" name="report" [(ngModel)]="report" (ngModelChange)="onReportChange($event)"  [ngClass]="{ 'select-not-empty': report }" > 
              <option *ngFor="let report of reports | keyvalue" [ngValue]="report.value">
                {{report.value.name}}
              </option>
            </select>
            <label>Select report</label>
          </div>
          <form #getReportForm="ngForm" (ngSubmit)="getReportForm.form.valid && getReport()">
            <div *ngIf="report">
              <div class="container">
                <div class="row row-cols-auto">
                  <div *ngFor="let parameter of params" class="p-0 pb-2 pe-3" >
                    <ng-container [ngSwitch]="parameter.type">
                      <div *ngSwitchCase="paramTypes.dateRange"
                      class="form-floating"
                      >
                        <input
                          class="form-control"
                          name="date"
                          type="date"
                          [(ngModel)]="parameter.value"
                          [ngClass]="{
                            'is-invalid': getReportForm.submitted && getReportForm.invalid
                          }"
                          [required]="parameter.required"
                        />
                        <label [ngClass]="{ 'field-required': parameter.required}">{{getParamLabel(parameter.paramName)}}</label>
                      </div>
                      <div *ngSwitchCase="paramTypes.string" class="form-floating">
                        <input 
                        class="form-control"  
                        name="textInput"
                          type="text"
                          [(ngModel)]="parameter.value"
                          #valueInput="ngModel"
                          [ngClass]="{
                            'is-invalid': getReportForm.submitted && valueInput.invalid && parameter.required
                          }"
                          [required]="parameter.required"
                        />
                        <label [ngClass]="{ 'field-required': parameter.required }">
                          {{getParamLabel(parameter.paramName)}}
                        </label>
                      </div>
                      <div *ngSwitchCase="paramTypes.number" class="form-floating">
                        <input 
                        class="form-control"
                          name="textInput"
                          type="number"
                          [(ngModel)]="parameter.value"
                          [required]="parameter.required"
                        />
                        <label [ngClass]="{ 'field-required': parameter.required }">
                          {{getParamLabel(parameter.paramName)}}
                        </label>
                      </div>
                    </ng-container>
                  </div>
                </div>
                <div class="row mt-3" >
                  <div class="col text-end">
                    <button 
                      type="submit" 
                      class="btn btn-primary px-4 py-2 rounded-pill"
                      [disabled]="isBusy"
                      >
                      Generate
                      <span
                        class="spinner-grow spinner-grow-sm"
                        role="status"
                        aria-hidden="true"
                        *ngIf="isBusy"
                      ></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div *ngIf="reportResponse" class="d-flex justify-content-end mb-4">
        <button class="btn btn-primary rounded-pill" (click)="download()">
          Download as .xlsx
        </button>
      </div>
      <app-report-table *ngIf="reportResponse" [report]="reportResponse" [colsMeta]="reportResponse.colsMeta"></app-report-table>
      <div *ngIf="reportResponse === null && getReportForm.submitted">
       <div class="card">
         <div class="card-body">
          <span>There is no data to show for your parameters.</span>
        </div>
       </div>
      </div>
    </div>
  </div>
  
  <app-modal #timeoutErrorInfoModal >
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title fw-bold">Report failure</h5>
        <button type="button" class="btn-close" (click)="timeoutErrorInfoModal.hide()"  aria-label="Close">
        </button>
      </div>
      <div class="modal-body">
        <div>
          <span>{{error?.userMessage}}</span>
        </div>
      </div>
      <div class="modal-footer text-end">
        <button type="button" class="btn btn-primary" (click)="timeoutErrorInfoModal.hide()">Ok</button>
      </div>
    </div>
  </app-modal>
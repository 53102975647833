import { Component, Input, OnInit } from '@angular/core';
import { ColumnObject, ReportResponse } from 'src/app/model/report';

@Component({
  selector: 'app-report-table',
  templateUrl: './report-table.component.html',
  styleUrls: ['./report-table.component.scss']
})

export class ReportTableComponent implements OnInit {

  @Input() report: ReportResponse
  @Input() colsMeta: ColumnObject[]
  sortColumn: string
  sortDirection: 'asc' | 'desc' | null = null

  constructor() { }

  ngOnInit(): void { }

  getColLabel(col: ColumnObject){
    return col.columnLabel
  }

  getColLabelPrettified(col: ColumnObject){
    return col.columnLabel[0].toUpperCase() + col.columnLabel.slice(1).replaceAll("_", " ")
  }

  getColIndex(colLabel: string){
    return this.report.colsMeta.findIndex(col =>  col.columnLabel === colLabel)
  }

  getColTypeByLabel(colLabel: string){
    return this.report.colsMeta.find(col => col.columnLabel === colLabel).columnType
  }

  getColTypeByIndex(colIndex: number){
    return this.report.colsMeta[colIndex].columnType
  }

  getValue(value: any, index: number){
    if(!value) return 

    const colType = this.getColTypeByIndex(index)
    if(colType === 'Date'){
      let date = new Date(value)
      return date.toLocaleString()
    }
    else return value
  }

  sortTable(columnLabel: string){
    
    if(this.sortColumn === columnLabel){ // change direction
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc'
    } else {
      this.sortColumn = columnLabel
      this.sortDirection = 'asc'
    }
    this.sortBy(this.sortColumn)
  }

  sortBy(columnLabel: string){
    const colIndex = this.getColIndex(columnLabel)
    const colType = this.getColTypeByLabel(columnLabel)

    this.report.data = this.report.data.sort((a, b) => {
      let valueA = a[colIndex]
      let valueB = b[colIndex]

      if(valueA == null && valueB == null) {
        return 0
      } else if (valueA == null) {
        return this.sortDirection === 'asc' ? -1 : 1
      } else if (valueB == null) {
        return this.sortDirection === 'asc' ? 1 : -1
      }

      switch(colType){
        case 'string':
          valueA = valueA.toLowerCase()
          valueB = valueB.toLowerCase()
          break;

        case 'Date':
          valueA = new Date(valueA).getTime()
          valueB = new Date(valueB).getTime()
          break;
      }

      if(valueA < valueB) {
        return this.sortDirection === 'asc' ? -1 : 1
      } else if (valueA > valueB) {
        return this.sortDirection === 'asc' ? 1 : -1
      } else {
        return 0
      }
      
    })
  }
}
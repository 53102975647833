import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ManagerMenuComponent } from './layout/with-menu/manager-menu/manager-menu.component';
import { EmptyNavBarComponent } from './layout/with-menu/empty-nav-bar/empty-nav-bar.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { ForbiddenComponent } from './pages/forbidden/forbidden.component';
import { RootComponent } from './pages/root/root.component';
import { LogoutComponent } from './pages/logout/logout.component';
import { NoMenuComponent } from './layout/no-menu/no-menu.component';
import { LoginComponent } from './pages/login/login.component';
import { OauthComponent } from './pages/oauth/oauth.component';
import { RegisterComponent } from './pages/register/register.component';
import { OpenComponent } from './pages/open/open.component';
import { AuthGuardService } from './services/col2/auth-guard.service';
import { RoleGuardService } from './services/col2/role-guard.service';
import { ManagerDashboardComponent } from './pages/manager-dashboard/manager-dashboard.component';
import { ManagerStudentsListPageComponent } from './pages/manager-students-list-page/manager-students-list-page.component';
import { ManagerTeachersListPageComponent } from './pages/manager-teachers-list-page/manager-teachers-list-page.component';
import { LessonsReportPageComponent } from './pages/lessons-report-page/lessons-report-page.component';
import {
  InvitationComponentAction,
  InvitationComponentData,
  InvitationRoleType,
  ManagerPersonInvitationFormPageComponent,
} from './pages/manager-person-invitation-form-page/manager-person-invitation-form-page.component';
import { TeachersLessonTypesReportPageComponent } from './pages/teachers-lesson-types-report-page/teachers-lesson-types-report-page.component';
import { ManagerTeacherDetailsLayoutComponent } from './layout/manager-teacher-details-layout/manager-teacher-details-layout.component';
import { ManagerTeacherLessonsPageComponent } from './pages/manager-teacher-lessons-page/manager-teacher-lessons-page.component';
import { ManagerLessonDetailsPageComponent } from './pages/manager-lesson-details-page/manager-lesson-details-page.component';
import { ManagerTeacherProfilePageComponent } from './pages/manager-teacher-profile-page/manager-teacher-profile-page.component';
import { ManagerStudentDetailsLayoutComponent } from './layout/manager-student-details-layout/manager-student-details-layout.component';
import { ManagerStudentLessonsPageComponent } from './pages/manager-student-lessons-page/manager-student-lessons-page.component';
import { ManagerStudentProfilePageComponent } from './pages/manager-student-profile-page/manager-student-profile-page.component';
import { StudentsProductsPageComponent } from './pages/students-products-page/students-products-page.component';
import { ManagerStudentCspaMonitorComponent } from './pages/manager-student-cpsa-monitor/manager-student-cspa-monitor.component';
import { ManagerStudentCasaProfilePageComponent } from './pages/manager-student-casa-profile-page/manager-student-casa-profile-page.component';
import { ManagerTeacherCasaProfilePageComponent } from './pages/manager-teacher-casa-profile-page/manager-teacher-casa-profile-page.component';
import { SchoolGroupsPageComponent } from './pages/school-groups-page/school-groups-page.component';
import { RoomTemplateDetailsPageComponent } from './pages/room-template-details-page/room-template-details-page.component';
import { ScheduleDetailsPageComponent } from './pages/schedule-details-page/schedule-details-page.component';
import { RoomDetailsPageComponent } from './pages/room-details-page/room-details-page.component';
import { ManagerDashboardPageComponent } from './pages/video-dashboard-page/manager-dashboard-page.component';
import { SchoolActivitiesPageComponent } from './pages/school-activities-page/school-activities-page.component';
import { TeacherAttendanceReportPageComponent } from './pages/teacher-attendance-report-page/teacher-attendance-report-page.component';
import { StudentRootPageComponent } from './pages/student/student-root-page/student-root-page.component';
import { TeacherRootPageComponent } from './pages/teacher/teacher-root-page/teacher-root-page.component';
import { StudentAreaLayoutComponent } from './layout/student-area-layout/student-area-layout.component';
import { TeacherAreaLayoutComponent } from './layout/teacher-area-layout/teacher-area-layout.component';
import { StudentRoomDetailsPageComponent } from './pages/student/student-room-details-page/student-room-details-page.component';
import { StudentScheduleDetailsPageComponent } from './pages/student/student-schedule-details-page/student-schedule-details-page.component';
import { TeacherScheduleDetailsPageComponent } from './pages/teacher/teacher-schedule-details-page/teacher-schedule-details-page.component';
import { TeacherRoomDetailsPageComponent } from './pages/teacher/teacher-room-details-page/teacher-room-details-page.component';
import { DocumentsBrowserComponent } from './pages/documents-browser/documents-browser.component';
import { TeachersProductsPageComponent } from './pages/teachers-products-page/teachers-products-page.component';
import { PaymentCancelComponent } from './pages/manager/payment-cancel/payment-cancel.component';
import { PaymentConfirmComponent } from './pages/manager/payment-confirm/payment-confirm.component';
import { UserRole } from './services/col2/auth.service';
import { AccessListGuardService } from './services/access-list-guard.service';
import { AccessRequired } from './services/functional-access.service';
import { AdminMenuComponent } from './layout/with-menu/admin-menu/admin-menu.component';
import { AdminDashboardComponent } from './pages/admin/admin-dashboard/admin-dashboard.component';
import { SchoolListPageComponent } from './pages/school-list-page/school-list-page.component';
import { AdminSchoolDetailsPageComponent } from './pages/admin/admin-school-details-page/admin-school-details-page.component';
import { ManagerStudentCreditsPageComponent } from './pages/manager-student-credits-page/manager-student-credits-page.component';
import { AdminSchoolNotesComponent } from './components/admin/admin-school-notes/admin-school-notes.component';
import { AdminSchoolDashboardComponent } from './components/admin/admin-school-dashboard/admin-school-dashboard.component';
import { AdminSchoolEditComponent } from './components/admin-school-edit/admin-school-edit.component';
import { AdminSchoolPaymentListComponent } from './components/admin-school-payment-list/admin-school-payment-list.component';
import { NewsPageComponent } from './pages/news-page/news-page.component';
import { NewsDetailsPageComponent } from './pages/news-details-page/news-details-page.component';
import {TeacherLessonsListComponent} from "./components/teacher/teacher-lessons-list/teacher-lessons-list.component";
import {
  TeacherSchedulesWeekSummaryCalendarComponent
} from "./components/teacher/teacher-schedules-week-summary-calendar/teacher-schedules-week-summary-calendar.component";
import {GroupListPageComponent} from "./pages/groups/group-list-page/group-list-page.component";
import {GroupEditPageComponent} from "./pages/groups/group-edit-page/group-edit-page.component";
import {GroupDetailsPageComponent} from "./pages/groups/group-details-page/group-details-page.component";
import {
  ManagerStudentExamResultsComponent
} from "./components/manager-student-exam-results/manager-student-exam-results.component";
import { ReportsPageComponent } from './pages/reports-page/reports-page.component';

const routes: Routes = [
  {
    path: '',
    component: EmptyNavBarComponent,
    children: [
      { path: 'forbidden', component: ForbiddenComponent },
      { path: '404', component: NotFoundComponent },
      { path: '', component: RootComponent },
      { path: 'logout', component: LogoutComponent },
    ],
  },
  {
    path: '',
    component: NoMenuComponent,
    children: [
      { path: 'login', component: LoginComponent },
      { path: 'oauth', component: OauthComponent },
      { path: 'register', component: RegisterComponent },
      { path: 'open', component: OpenComponent },
    ],
  },
  {
    path: 'sa/school/:schoolId/student/:studentId',
    canActivate: [AuthGuardService, RoleGuardService],
    component: StudentAreaLayoutComponent,
    data: { roles: [UserRole.Student] },
    children: [
      { path: 'room/:roomUuid', component: StudentRoomDetailsPageComponent },
      {
        path: 'schedule/:scheduleId',
        component: StudentScheduleDetailsPageComponent,
      },
      { path: '', component: StudentRootPageComponent },
    ],
  },
  {
    path: 'ta/school/:schoolId/teacher/:teacherId',
    canActivate: [AuthGuardService, RoleGuardService],
    component: TeacherAreaLayoutComponent,
    data: { roles: [UserRole.Teacher] },
    children: [
      { path: 'room/:roomUuid', component: TeacherRoomDetailsPageComponent },
      {
        path: 'schedule/:scheduleId',
        component: TeacherScheduleDetailsPageComponent,
      },
      { path: '', component: TeacherRootPageComponent },
    ],
  },
  {
    path: '',
    component: ManagerMenuComponent,
    canActivate: [AuthGuardService, RoleGuardService],
    data: { roles: [UserRole.Manager] },
    children: [
      {
        path: 'school/:schoolId',
        children: [
          {
            path: 'dashboard',
            component: ManagerDashboardComponent,
          },
          {
            path: 'payment',
            children: [
              {
                path: 'cancel',
                component: PaymentCancelComponent,
              },
              {
                path: 'confirm',
                component: PaymentConfirmComponent,
              },
            ],
          },
          {
            path: 'students',
            component: ManagerStudentsListPageComponent,
          },
          {
            path: 'teachers',
            component: ManagerTeachersListPageComponent,
          },
          {
            path: 'student/new',
            component: ManagerPersonInvitationFormPageComponent,
            data: new InvitationComponentData(
              InvitationComponentAction.ActionInvite,
              InvitationRoleType.Student
            ),
          },
          {
            path: 'manager/new',
            component: ManagerPersonInvitationFormPageComponent,
            data: new InvitationComponentData(
              InvitationComponentAction.ActionInvite,
              InvitationRoleType.Manager
            ),
          },
          {
            path: 'teacher/new',
            component: ManagerPersonInvitationFormPageComponent,
            data: new InvitationComponentData(
              InvitationComponentAction.ActionInvite,
              InvitationRoleType.Teacher
            ),
          },
          {
            path: 'student/:studentId',
            component: ManagerStudentDetailsLayoutComponent,
            children: [
              {
                path: 'credits',
                component: ManagerStudentCreditsPageComponent,
              },
              {
                path: 'lessons',
                component: ManagerStudentLessonsPageComponent,
              },
              {
                path: 'lessons/:lessonId',
                component: ManagerLessonDetailsPageComponent,
              },
              {
                path: 'profile',
                component: ManagerStudentProfilePageComponent,
              },
              {
                path: 'products',
                component: StudentsProductsPageComponent,
              },
              {
                path: 'cspa-monitor',
                component: ManagerStudentCspaMonitorComponent,
              },
              {
                path: 'casa-profile',
                component: ManagerStudentCasaProfilePageComponent,
              },
              {
                path: 'exam-results',
                component: ManagerStudentExamResultsComponent,
              },
            ],
          },
          {
            path: 'teacher/:teacherId',
            component: ManagerTeacherDetailsLayoutComponent,
            children: [
              {
                path: 'lessons',
                component: ManagerTeacherLessonsPageComponent,
              },
              {
                path: 'lessons/:lessonId',
                component: ManagerLessonDetailsPageComponent,
              },
              {
                path: 'lessons-list',
                component: TeacherLessonsListComponent,
              },
              {
                path: 'lessons-calendar',
                component: TeacherSchedulesWeekSummaryCalendarComponent,
              },
              {
                path: 'profile',
                component: ManagerTeacherProfilePageComponent,
              },
              {
                path: 'casa-profile',
                component: ManagerTeacherCasaProfilePageComponent,
              },
              {
                path: 'products',
                component: TeachersProductsPageComponent,
              },
            ],
          },
          {
            path: 'reports',
            children: [
              {
                path: 'teacher-lesson-types',
                component: TeachersLessonTypesReportPageComponent,
              },
              {
                path: 'lessons',
                component: LessonsReportPageComponent,
              },
              {
                path: 'video/activities',
                component: SchoolActivitiesPageComponent,
              },
              {
                path: 'video/teacher-attendance',
                component: TeacherAttendanceReportPageComponent,
              },
              {
                path: 'new-reports',
                component: ReportsPageComponent,
              }
            ],
          },
          {
            path: 'groups',
            component: SchoolGroupsPageComponent,
            canActivate: [AccessListGuardService],
            data: { require: [AccessRequired.Video] },
          },
          {
            path: 'groups/:id',
            component: RoomTemplateDetailsPageComponent,
            canActivate: [AccessListGuardService],
            data: { require: [AccessRequired.Video] },
          },
          {
            path: 'v2_groups',
            component: GroupListPageComponent
          },
          {
            path: 'v2_groups/new',
            component: GroupEditPageComponent
          },
          {
            path: 'v2_groups/group/:groupId/edit',
            component: GroupEditPageComponent
          },
          {
            path: 'v2_groups/group/:groupId',
            component: GroupDetailsPageComponent
          },
          {
            path: 'schedules/:id',
            component: ScheduleDetailsPageComponent,
            canActivate: [AccessListGuardService],
            data: { require: [AccessRequired.Video] },
          },
          {
            path: 'rooms/:roomUuid/details',
            component: RoomDetailsPageComponent,
            canActivate: [AccessListGuardService],
            data: { require: [AccessRequired.Video] },
          },
          {
            path: 'video',
            component: ManagerDashboardPageComponent,
            canActivate: [AccessListGuardService],
            data: { require: [AccessRequired.Video] },
          },
          {
            path: 'schools',
            component: SchoolListPageComponent,
          },
          {
            path: 'documents',
            children: [{ path: '**', component: DocumentsBrowserComponent }],
          },
          {path: 'news', component: NewsPageComponent},
          {path: 'news/:newsId', component: NewsDetailsPageComponent}
        ],
      },
    ],
  },
  {
    path: '',
    component: AdminMenuComponent,
    canActivate: [AuthGuardService, RoleGuardService],
    data: { roles: [UserRole.Admin] },
    children: [
      {
        path: 'admin',
        children: [
          {
            path: '',
            redirectTo: 'dashboard',
            pathMatch: 'full',
          },
          {
            path: 'dashboard',
            component: AdminDashboardComponent,
          },
          {
            path: 'school',
            component: SchoolListPageComponent,
          },
          {
            path: 'school/:schoolId',
            component: AdminSchoolDetailsPageComponent,
            children: [
              {
                path: '', redirectTo: 'dashboard', pathMatch: 'full',
              },
              {
                path: 'dashboard', component: AdminSchoolDashboardComponent
              },
              {
                path: 'notes', component: AdminSchoolNotesComponent
              },
              {
                path: 'edit', component: AdminSchoolEditComponent
              },
              {
                path: 'payments', component: AdminSchoolPaymentListComponent
              }
            ]
          },
          {
            path: 'documents',
            children: [{ path: '**', component: DocumentsBrowserComponent }],
          },
          {path: 'groups/:id', component: RoomTemplateDetailsPageComponent,
            canActivate: [AccessListGuardService], data: {require: [AccessRequired.Video]}
          },
          {path: 'schedules/:id', component: ScheduleDetailsPageComponent,
            canActivate: [AccessListGuardService], data: {require: [AccessRequired.Video]}
          },
          {path: 'rooms/:roomUuid/details', component: RoomDetailsPageComponent,
            canActivate: [AccessListGuardService], data: {require: [AccessRequired.Video]}
          },
          {path: 'video', component: ManagerDashboardPageComponent,
            canActivate: [AccessListGuardService], data: {require: [AccessRequired.Video]}
          },
          {path: 'documents', children: [{path: "**", component: DocumentsBrowserComponent}]},
          // {path: 'news', component: NewsPageComponent},
          // {path: 'news/:newsId', component: NewsDetailsPageComponent}
          {path: 'reports', component: ReportsPageComponent},
        ]
      }
    ]
  },
  { path: '**', redirectTo: '/404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

import { environment } from "src/environments/environment"
import { UserRole } from "../services/col2/auth.service"

export class ReportSpecification {
    name: string
    endpointApiBase: string
    endpoint: string
    parameters: ReportParams[]
    isForCallanOnline: boolean
    roles: UserRole[]

    constructor(name: string, endpointApiBase: string, endpoint: string, parameters: ReportParams[], isForCallanOnline: boolean, roles: UserRole[]){
        this.name = name
        this.endpointApiBase = endpointApiBase
        this.endpoint = endpoint
        this.parameters = parameters
        this.isForCallanOnline = isForCallanOnline
        this.roles = roles
    }
}

export class ReportResponse {
    meta: ReportMeta
    colsMeta: ColumnObject[]
    data: any[][]
}

export class ReportParams { 
    paramName: string[]
    type: ParamTypes
    required: boolean

    constructor(paramName: string[], type: ParamTypes, required: boolean){
        this.paramName = paramName,
        this.type = type,
        this.required = required
    }
}

export class ColumnObject {
    columnName: string
    columnLabel: string
    columnType: string
}

export class ReportMeta {
    tableName: string
    date: string
    databaseName: string
    reportName: string
    params: any
}

export enum ReportTypes {
    Prova = 'Prova',
    OCC_Attendance = 'OCC_Attendance',
    COL_Orders = 'COL_Orders',
    Ebook_Credits_Distribution = 'Ebook_Credits_Distribution',
    Ebook_Credits_Orders = 'Ebook_Credits_Orders'
}

export enum ParamTypes {
    date, // single date
    dateRange,  
    dateTime, // date with time selector 
    dateTimeRange,
    string,
    number,
    select,
    checkbox,
    radioButton
}

export enum ParamNames {
    dateFrom = 'dateFrom',
    dateTo = 'dateTo',
    email = 'email',
    schoolName = 'schoolName',
    schoolIdParam = 'schoolIdParam'
}

export class ReportRequestParam {
    paramName: string
    required: boolean
    type: ParamTypes
    value: any
}

export const ReportsMap = new Map<string, ReportSpecification>(
    [   
        [
            ReportTypes.Prova, 
            new ReportSpecification('Prova', environment.apiEndpoint,'/prova', 
                [
                    new ReportParams([ParamNames.dateFrom, ParamNames.dateTo], ParamTypes.dateRange, true)
                ],
                true,
                [UserRole.Admin, UserRole.Manager]
            ),
            
        ],
        [   
            ReportTypes.OCC_Attendance, 
            new ReportSpecification('OCC Attendance', environment.videoApiBase, `/occ-attendance`, 
                [
                    new ReportParams([ParamNames.dateFrom, ParamNames.dateTo], ParamTypes.dateRange, true), 
                    new ReportParams([ParamNames.email], ParamTypes.string, false),
                    new ReportParams([ParamNames.schoolName], ParamTypes.string, false),
                    new ReportParams([ParamNames.schoolIdParam], ParamTypes.number, false)
                ],
                false,
                [UserRole.Admin, UserRole.Manager]
            )
        ],
        [   
            ReportTypes.COL_Orders, 
            new ReportSpecification('COL orders', environment.transApiBase, '/col-orders', 
                [
                    new ReportParams([ParamNames.dateFrom, ParamNames.dateTo], ParamTypes.dateRange, true)
                ],
                true,
                [UserRole.Admin, UserRole.Manager]
            )
        ],
        [   
            ReportTypes.Ebook_Credits_Distribution, 
            new ReportSpecification('eBook credits distribution', environment.transApiBase, '/eBook-credits-distribution', 
                [
                    new ReportParams([ParamNames.dateFrom, ParamNames.dateTo], ParamTypes.dateRange, true), 
                    new ReportParams([ParamNames.email], ParamTypes.string, false),
                    new ReportParams([ParamNames.schoolName], ParamTypes.string, false),
                    new ReportParams([ParamNames.schoolIdParam], ParamTypes.number, false)
                ],
                false,
                [UserRole.Admin, UserRole.Manager]
            )
        ],
        [   
            ReportTypes.Ebook_Credits_Orders, 
            new ReportSpecification('eBook credits orders', environment.transApiBase, '/eBook-credits-orders', 
                [
                    new ReportParams([ParamNames.dateFrom, ParamNames.dateTo], ParamTypes.dateRange, true)
                ],
                false,
                [UserRole.Admin, UserRole.Manager]
            )
        ]
    ]
)

export class ExcelReportAddon {
    type: ExcelReportAddonTypes
    text: ExcelReportAddonTexts
    constructor(type: ExcelReportAddonTypes, text: ExcelReportAddonTexts) {
        this.type = type
        this.text = text
    }
}

export enum ExcelReportAddonTypes {
    generatedOn,
    timesExpressedInfo
}

export enum ExcelReportAddonTexts {
    generatedOn = 'Generated on: ',
    timesExpressedInfo = 'Times shown below are expressed in the official server time UTC (Coordinated Universal Time).'
}

export class ExcelReportSpecification {
    startOfTable: string
    excelSpecs: ExcelReportAddon[]

    constructor(startOfTable: string, excelSpecs: ExcelReportAddon[]){
        this.startOfTable = startOfTable
        this.excelSpecs = excelSpecs
    }
}

export const ExcelReportsSpecification = new Map<string, ExcelReportSpecification>(
    [   
        [
            ReportTypes.Prova,
            new ExcelReportSpecification(
                'A3',
                [ new ExcelReportAddon(ExcelReportAddonTypes.generatedOn, ExcelReportAddonTexts.generatedOn)]
            )
        ],
        [
            ReportTypes.COL_Orders,
            new ExcelReportSpecification(
                'A3',
                [ new ExcelReportAddon(ExcelReportAddonTypes.generatedOn, ExcelReportAddonTexts.generatedOn)]
            )
        ],
        [
            ReportTypes.Ebook_Credits_Distribution,
            new ExcelReportSpecification(
                'A3',
                [ new ExcelReportAddon(ExcelReportAddonTypes.generatedOn, ExcelReportAddonTexts.generatedOn)]
            )
        ],
        [
            ReportTypes.Ebook_Credits_Orders,
            new ExcelReportSpecification(
                'A3',
                [ new ExcelReportAddon(ExcelReportAddonTypes.generatedOn, ExcelReportAddonTexts.generatedOn)]
            )
        ],
        [
            ReportTypes.OCC_Attendance,
            new ExcelReportSpecification(
                'A4',
                [ 
                    new ExcelReportAddon(ExcelReportAddonTypes.generatedOn, ExcelReportAddonTexts.generatedOn),
                    new ExcelReportAddon(ExcelReportAddonTypes.timesExpressedInfo, ExcelReportAddonTexts.timesExpressedInfo),
                ]
            )
        ]
    ]
)